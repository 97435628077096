<section class="contact">
  <div class="title">
    <h1>Get in touch</h1>
  </div>
  <div class="subtitle"><p>We would love hearing from you</p></div>
  <div class="spacer"></div>

  <div *ngIf="emailSent">
    <h2>🚀Thank you for getting in contact with us! 🚀</h2>
    <iframe
      src="https://giphy.com/embed/QAsBwSjx9zVKoGp9nr"
      width="480"
      height="345"
      frameBorder="0"
      class="giphy-embed"
      allowFullScreen
    ></iframe>
    <div>
      <a
        style="font-size: 0.5em"
        href="https://giphy.com/gifs/thank-you-keanu-reeves-QAsBwSjx9zVKoGp9nr"
        >via GIPHY</a
      >
    </div>
  </div>

  <div *ngIf="loading" class="loading-spinner">
    <mat-spinner color="accent" ></mat-spinner>
  </div>


  <form
    *ngIf="!emailSent && !loading"
    class="contact-form"
    [formGroup]="contactForm"
    (ngSubmit)="sendEmail(contactForm)"
  >
    <div class="error" *ngIf="error">
      <mat-error>
        <div>Sending email failed<br /></div>
        {{ error }}
      </mat-error>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Message</mat-label>
      <textarea matInput formControlName="message"></textarea>
    </mat-form-field>

    <mat-checkbox formControlName="privacy">
      I agree to the terms and conditions laid out in the
      <a routerLink="privacy-policy">Privacy Policy</a>
    </mat-checkbox>
    <br />
    <re-captcha formControlName="recaptcha"> </re-captcha>
    <button [disabled]="contactForm.invalid" mat-raised-button color="primary">
      Send
    </button>
  </form>
</section>
