import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import {
  AngularFireAuthGuard,
  loggedIn,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/']);

export const routes: Routes = [
  { path: '', component: HomepageComponent },
  {
    path: 'projects',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadComponent: () =>
      import('./projects/projects.component').then(
        (mod) => mod.ProjectsComponent
      ),
  },
  {
    path: 'smart-meter',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadComponent: () =>
      import('./smart-meter/smart-meter.component').then(
        (mod) => mod.SmartMeterComponent
      ),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./privacy-policy/privacy-policy.component').then(
        (mod) => mod.PrivacyPolicyComponent
      ),
  },
  {
    path: 'christmas-2025',
    loadComponent: () =>
      import('./christmas-card/christmas-card.component').then(
        (mod) => mod.ChristmasCardComponent
      ),
  },
  {
    path: 'admin-zone',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadComponent: () =>
      import('././adminzone/realhome/realhome.component').then(
        (mod) => mod.RealhomeComponent
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
