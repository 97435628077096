<div class="close" (click)="close()">
  <mat-icon>close</mat-icon>
</div>
<div class="logo">
  <img src="../../../assets/ERBO6-1-300x138.png" alt="" />
</div>
<form
  [formGroup]="form"
  (keyup.enter)="
    form.untouched || form.invalid || loading ? undefined : login()
  "
>
  <div class="login-container">
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input
        matInput
        placeholder="email"
        formControlName="email"
        name="email"
        required
      />
      <mat-error>Invalid email</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>password</mat-label>
      <input
        matInput
        type="password"
        placeholder="password"
        formControlName="password"
        name="password"
        required
      />
      <mat-error>Invalid password</mat-error>
    </mat-form-field>
  </div>
</form>

<div class="login-button">
  <button
    [disabled]="form.untouched || form.invalid || loading"
    #loginButton
    mat-flat-button
    color="primary"
    (click)="login()"
    class="action-button"
  >
    <span *ngIf="!loading">sign-in</span>
    <mat-icon *ngIf="loading"
      ><mat-progress-spinner
        diameter="20"
        mode="indeterminate"
        strokeWidth="11%"
      >
      </mat-progress-spinner
    ></mat-icon>
  </button>
</div>
