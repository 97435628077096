import { Component, OnInit } from '@angular/core';
import { FancySliceComponent } from '../fancy-slice/fancy-slice.component';

@Component({
  selector: 'app-philosophy',
  templateUrl: './philosophy.component.html',
  styleUrls: ['./philosophy.component.scss'],
  standalone: true,
  imports: [
    FancySliceComponent
  ]
})
export class PhilosophyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scrollToContact() {
    document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
  }
}
