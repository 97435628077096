import { Component, OnInit } from '@angular/core';
import { FancySliceComponent } from '../fancy-slice/fancy-slice.component';

@Component({
  selector: 'app-landing-view',
  templateUrl: './landing-view.component.html',
  styleUrls: ['./landing-view.component.scss'],
  standalone: true,
  imports: [
    FancySliceComponent
  ]
})
export class LandingViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
