<section class="offering">
  <div class="title">
    <h1>What we are passionate about</h1>
  </div>
  <div class="subtitle">
    <p>is where we can help you</p>
  </div>

  <div class="divider">
    <div class="divider-line"></div>
    <div class="divider-ball"></div>
    <div class="divider-line"></div>
  </div>

  <section class="offering-cards">
    <article class="left">
      <h3>Embedded systems</h3>
      <p>
        One of the many challenges that companies encounter is making embedded
        devices connected to the cloud. We have the experience with the hardware
        and firmware to make this possible. Some example cases where we can
        consult and help are:
      </p>

      <ul>
        <li>
          What is the best connection medium for or use case (LoRa, GSM,
          LTE-M,Wi-Fi)?
        </li>
        <li>How can we update our device over the air(FOTA)?</li>
        <li>
          How do we connect our device to the right backend and how do we
          provision it?
        </li>
      </ul>
    </article>
    <article class="middle">
      <h3>Consultancy</h3>
      <p>
        Providing expertise in embedded systems and everything regarding
        application development. We can be a valuable asset in your project
        working at the core or by creating a new perspective that allows you to
        identify key points and improve your product.
      </p>

      <button mat-stroked-button color="primary" (click)="scrollToContact()">
        Get in touch
      </button>
    </article>
    <article class="right">
      <h3>Software development</h3>
      <p>
        With our experience in software development, we can prototype your idea
        into a quick and great success. By keeping you close to our process we
        can quickly shape the product. Once you confirm the prototype we turn
        the app into a fully-fledged production-ready product for multiple
        platforms.
      </p>
      <p>Example cases:</p>
      <ul>
        <li>Hybrid web + mobile app using ionic</li>
        <li>Cross-platform native with flutter or react</li>
      </ul>
    </article>
  </section>
</section>
