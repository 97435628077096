// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  siteUrl: 'https://erbo-350813.web.app',
  recaptcha: '6LeAaPEgAAAAALZKDJ3cOdtTombgD1mG-3Gm7CeP',
  firebase: {
    projectId: 'erbo-350813',
    appId: '1:70567330363:web:b96ac9050238da9f18121e',
    storageBucket: 'erbo-350813.appspot.com',
    apiKey: 'AIzaSyAXwdQXwOgbXCp8k0TkEPAcPLSTVEcwaDE',
    authDomain: 'erbo-350813.firebaseapp.com',
    messagingSenderId: '70567330363',
    measurementId: 'G-52CK2F5N6M',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
