import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginmodalComponent } from './components/loginmodal/loginmodal.component';
import { AuthService } from './services/auth.service';
import { SidebarService } from './services/sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  private unsubscribe = new Subject();
  public isAuth: Observable<firebase.default.User | null>;
  @ViewChild('sideNav') sidenav!: MatSidenav;

  title = 'ERBO-website';

  constructor(
    public router: Router,
    public cookie: NgcCookieConsentService,
    public sidebarService: SidebarService,
    public dialog: MatDialog,
    private meta: Meta,
    private auth: AuthService
  ) {
    this.meta.updateTag(
      {
        content: environment.siteUrl,
      },
      'property="og:url"'
    );
    this.meta.updateTag(
      {
        content: environment.siteUrl + '/assets/erbo.jpeg',
      },
      'property="og:image"'
    );

    this.meta.updateTag(
      {
        content: environment.siteUrl,
      },
      'property="twitter:url"'
    );

    this.meta.updateTag(
      {
        content: environment.siteUrl + '/assets/erbo.jpeg',
      },
      'property="twitter:image"'
    );
    this.isAuth = auth.userSubject.asObservable();
  }

  ngAfterViewInit(): void {
    this.sidebarService.status$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((status) => {
        if (status) {
          this.sidenav.open();
        } else {
          this.sidenav.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
  }

  scroll(id: string): void {
    // route to home if not on home when scroll buttons are clicked
    if (this.router.url !== '/' && !this.router.url.startsWith('/#')) {
      this.router.navigate([''], { fragment: id });
      return;
    }
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth' });
    this.router.navigate([], { fragment: id });
  }

  login() {
    // pop that login modal
    this.dialog.open(LoginmodalComponent, {
      panelClass: 'erbo-panel',
    });
  }
  logout() {
    this.auth.logout();
  }
  adminzone() {
    this.router.navigateByUrl('admin-zone');
  }
}
