import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebar.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { LoginmodalComponent } from '../loginmodal/loginmodal.component';
import { AuthService } from 'src/app/services/auth.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-erbo-header',
  templateUrl: './erbo-header.component.html',
  styleUrls: ['./erbo-header.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatToolbarModule, MatButtonModule, MatDialogModule, CommonModule],
})
export class ErboHeaderComponent implements OnInit {
  @Input() overlay = true;
  @Input() background = 'transparent';

  public isAuth: Observable<firebase.default.User | null>

  
  constructor(
    public sidebarService: SidebarService,
    public router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private cd: ChangeDetectorRef
  ) {

    this.isAuth = auth.userSubject.asObservable()
  }

  ngOnInit(): void {
  }

  goToHome(): void {
    this.router.navigate(['']);
  }

  scroll(id: string): void {
    // route to home if not on home when scroll buttons are clicked
    if (this.router.url !== '/' && !this.router.url.startsWith('/#')) {
      this.router.navigate([''], { fragment: id });
      return;
    }
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth' });
    this.router.navigate([], { fragment: id });
  }

  login() {
    // pop that login modal
    this.dialog.open(LoginmodalComponent, {
      panelClass: 'erbo-panel',
    });
  }

  async logout(){
    await this.auth.logout()
  }

  adminzone(){
    this.router.navigateByUrl('admin-zone');
  }
}
