import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
} from '@angular/fire/compat/analytics';
import {
  AngularFireFunctionsModule,
  REGION,
  USE_EMULATOR,
} from '@angular/fire/compat/functions';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './homepage/components/contact/contact.component';
import { cookieConfig } from './cookie-config';
import { MatDialogModule } from '@angular/material/dialog';
import { provideFirestore } from '@angular/fire/firestore';
import { getFirestore } from '@firebase/firestore';

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    BrowserAnimationsModule,
    ContactComponent,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
  ],
  providers: [
    ScreenTrackingService,
    {
      provide: REGION,
      useValue: 'europe-west1',
    },
    // {
    //   provide: USE_EMULATOR,
    //   useValue: environment.production ? false : ['localhost', 5001],
    // },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptcha } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
