<mat-sidenav-container>
  <mat-sidenav
    #sideNav
    [mode]="'over'"
    [position]="'end'"
    fixedInViewport="true"
    class="mat-elevation-z8"
  >
    <div class="close">
      <img src="../../../assets/ERBO6-1-300x138.png" alt="" />
      <mat-icon (click)="this.sidebarService.close()">close</mat-icon>
    </div>
    <div class="buttons">
      <button
        class="button"
        (click)="scroll('offering')"
        mat-basic-button
        color="primary"
      >
        work
      </button>
      <button
        class="button"
        (click)="scroll('philosophy')"
        mat-basic-button
        color="primary"
      >
        philosophy
      </button>
      <button
        class="button"
        (click)="scroll('contact')"
        mat-basic-button
        color="primary"
      >
        contact
      </button>
      <a  href="https://medium.com/@erbo-engineering" class="button"  mat-basic-button color="primary">
        blog
        </a>
        <button *ngIf="!(isAuth | async)" class="button" (click)="login()" mat-basic-button color="primary">
        login
      </button>
      <button *ngIf="(isAuth | async)" class="button" (click)="logout()" mat-basic-button color="primary">
        logout
      </button>
      <button *ngIf="(isAuth | async)" class="button" (click)="adminzone()" mat-basic-button color="primary">
        Admin zone</button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
