import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private statusSubject = new BehaviorSubject<boolean>(false);
  public status$: Observable<boolean> = this.statusSubject.asObservable();

  constructor() { 
  }

  close(): void {
    this.statusSubject.next(false);
  }

  open(): void {
    this.statusSubject.next(true);
  }
}
