import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'erbo.io',
  },
  position: 'bottom-right',
  theme: 'classic',
  palette: {
    popup: {
      background: '#007bd8',
      text: '#ffffff',
    },
    button: {
      background: '#f7931e',
      text: '#ffffff',
    },
  },
  type: 'info',
  content: {
    message:
      'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    link: 'Learn more',
    href: '/privacy-policy',
    policy: 'Cookie Policy',
  },
};
