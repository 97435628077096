import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, take, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: firebase.default.User | null = null;
  public userSubject = new BehaviorSubject<firebase.default.User | null>(null);

  constructor(private auth: AngularFireAuth, private router: Router) {
    auth.authState.subscribe((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
      }
      this.userSubject.next(this.user);
    });
  }

  /**
   * Helper function to get the user from firebase
   *
   * @returns The firebase user
   */
  async getCurrent(): Promise<firebase.default.User | null> {
    return firstValueFrom(
      this.userSubject.pipe(
        filter((user) => user !== null),
        take(1),
        timeout(2000)
      )
    ).catch((_error) => null);
  }

  async login(email: string, password: string) {
    let userCreds = await this.auth.signInWithEmailAndPassword(email, password);
    if (userCreds) {
      this.router.navigateByUrl('admin-zone');
    }
  }

  async logout() {
    this.router.navigateByUrl('/');
    return this.auth.signOut();
  }
}
