import { Component, OnInit } from '@angular/core';
import { ErboHeaderComponent } from '../components/erbo-header/erbo-header.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { LandingViewComponent } from './components/landing-view/landing-view.component';
import { OfferingComponent } from './components/offering/offering.component';
import { PhilosophyComponent } from './components/philosophy/philosophy.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  standalone: true,
  imports:[
    ErboHeaderComponent,
    LandingViewComponent,
    OfferingComponent,
    PhilosophyComponent,
    ContactComponent,
    FooterComponent
  ]
})
export class HomepageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
