<mat-toolbar
  class="toolbar"
  [style.--toolbar-position]="this.overlay ? 'absolute' : 'relative'"
  [style.--toolbar-background-color]="this.background"
>
    <div class="erbo-logo" (click)="goToHome()">
      <img src="../../../assets/ERBO6-1-300x138.png" alt="" />
    </div>
    <span class="spacer"></span>
    <button
      class="button"
      (click)="scroll('offering')"
      mat-button
    >
      work
    </button>
    <button
      class="button"
      (click)="scroll('philosophy')"
      mat-button
    >
      philosophy
    </button>
    <button
      class="button"
      (click)="scroll('contact')"
      mat-button
    >
      contact
    </button>
    <a  href="https://medium.com/@erbo-engineering" class="button"  mat-basic-button color="primary">blog
    </a>
    <button *ngIf="(isAuth | async)" class="button" (click)="adminzone()" mat-button>Admin zone</button>
    <button *ngIf="!(isAuth | async)" class="button" (click)="login()" mat-button>Login</button>
    <button *ngIf="(isAuth | async)" class="button" (click)="logout()" mat-button>Logout</button>

    <mat-icon (click)="this.sidebarService.open()" >menu</mat-icon>
</mat-toolbar>
