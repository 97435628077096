import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatProgressSpinnerModule,
  ]
})
export class ContactComponent implements OnInit {
  public loading = false;
  public error = '';
  public emailSent = false;
  public sendEmailCallable: (form: any) => Observable<any>;
  public contactForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', Validators.required),
    privacy: new FormControl(false, Validators.required),
    recaptcha: new FormControl(null, Validators.required),
  });
  constructor(firebaseFunctions: AngularFireFunctions) {
    this.sendEmailCallable = firebaseFunctions.httpsCallable('emailMessage');
  }

  ngOnInit(): void { }

  sendEmail(form: FormGroup): void {
    this.error = '';
    this.loading = true;
    this.sendEmailCallable(form.value).pipe(take(1)).subscribe(
      () => {
        this.emailSent = true;
        this.loading = false;

      },
      (error) => {
        this.error = error?.message;
        this.loading = false;
      },
    );
  }
}
