<section>
  <div class="container">
    <div class="title">
      <h1>Our Philosophy</h1>
    </div>

    <p class="mat-body">
      At ERBO Engineering we believe that, with the right amount of expertise
      and knowledge combined with great enthusiasm, we are able to overcome
      every hurdle and challenge. While developing a product, you may face
      challenges without knowing how to efficiently solve them. These challenges
      can range from dealing with multiple stakeholders to not being able to see
      the big picture anymore as you're stuck in your own perspective. At times
      like these, it can be helpful to have a partner who understands the
      problem and can add insightful solutions, such as helping with
      stakeholder-issues or technical problems.
    </p>

    <button mat-stroked-button color="primary" (click)="scrollToContact()">
      Get in touch
    </button>
  </div>
  <app-fancy-slice></app-fancy-slice>
</section>
